import { getDataRequest, getListRequest } from "@/sea/apis/BaseService";
import { ref } from "vue";
import { ProductResponse } from "@/sea/apis/models/product/ProductResponse";

export const getProductsRequest = () => {
  const {
    responseRef,
    errorRef,
    listRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    getList,
  } = getListRequest<ProductResponse>();
  const productsRef = ref<Array<ProductResponse> | null | undefined>([]);

  const getProducts = async (
    name: string | null,
    branchId: number | null,
    productCategoryId: number | null,
    isDelivery: boolean | null,
    isEnabled: boolean | null,
    sort: string | null,
    pageNumber: number,
    pageSize: number
  ) => {
    await getList(true, "/products", {
      params: {
        name: name,
        branchId: branchId,
        productCategoryId: productCategoryId,
        isDelivery: isDelivery,
        isEnabled: isEnabled,
        sort: sort,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
    productsRef.value = listRef.value;
  };

  return {
    responseRef,
    errorRef,
    productsRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    getProducts,
  };
};

export const getProductRequest = () => {
  const { responseRef, errorRef, dataRef, getData } =
    getDataRequest<ProductResponse>();
  const productRef = ref<ProductResponse | null | undefined>(null);

  const getProduct = async (productId: number) => {
    await getData(true, `/products/${productId}`);
    productRef.value = dataRef.value;
  };

  return { responseRef, errorRef, productRef, getProduct };
};
