
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import LightAlert from "@/sea/components/alerts/LightAlert.vue";
import { getProductRequest } from "@/sea/apis/ProductsService";

export default defineComponent({
  name: "product",
  components: { LightAlert },
  props: {
    id: Number,
  },
  setup(props) {
    const router = useRouter();

    const id = props.id ?? 0;
    const { errorRef, productRef, getProduct } = getProductRequest();
    const loadingRef = ref(false);

    const fetchProductCategory = async (id: number) => {
      loadingRef.value = true;
      await getProduct(id);
      loadingRef.value = false;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        "sea.pages.product.title",
        ["sea.aside.categories.product.title", "sea.pages.products.title"],
        ["", router.resolve({ name: "productCategories" }).path]
      );
      await fetchProductCategory(id);
    });

    return {
      I18nUtils,
      loadingRef,
      productRef,
      errorRef,
    };
  },
});
